import { useContext, Fragment } from 'react';
import { ProductsContext } from '@global/ProductsContext';

const EmptyProducts = ({className}) => {
    const { loading } = useContext(ProductsContext);

    return (
        <div className={`tt-empty-search w-100 ${className}`}>
            <span className="tt-icon icon-g-84"/>
            {!loading ?
                <Fragment>
            <h2 className="tt-title">VACÍO</h2>
            <p>No pudimos encontrar productos!</p>
                </Fragment>
                : <Fragment>
                    <h2 className="tt-title">CARGANDO</h2>
                    <p>Estamos cargando los productos para vos</p>
                </Fragment> }
        </div>
    );
};

export default EmptyProducts;